import {MediaContentShowcaseItem} from '@onnit-js/ui/components/module/MediaContentShowcase';

const ingredients: MediaContentShowcaseItem[] = [
    {
        image: '2024-STS-Wpage-Ingredient-1.png',
        imageWidth: 560,
        imageHeight: 560,
        title: 'Cordyceps Sinensis',
        summary: 'A mushroom that grows in high-altitude regions.',
        detail: 'Helps energy utilization during exercise.†︎<span class="oui-sup">†︎</span>',
    },
    {
        image: '2024-STS-Wpage-Ingredient-2.png',
        imageWidth: 560,
        imageHeight: 560,
        title: 'Ashwagandha Root Extract',
        summary: 'An adaptogenic herb from India and North Africa, used for generations.',
        detail: 'Suggested to help promote muscle power and VO2 max performance, a measure of aerobic fitness.<span class="oui-sup">†︎</span>',
    },
    {
        image: '2024-STS-Wpage-Ingredient-3.png',
        imageWidth: 560,
        imageHeight: 560,
        title: 'Green Tea Leaf Extract',
        summary: 'A concentrated form of decaffeinated green tea.',
        detail: 'Helps support endurance performance.<span class="oui-sup">†︎;</span>',
    },
    {
        image: '2024-STS-Wpage-Ingredient-4.png',
        imageWidth: 560,
        imageHeight: 560,
        title: 'Rhodiola Extract',
        summary: 'An adaptogenic herb that grows in cold, mountainous regions.',
        detail: 'Supports time to exhaustion during endurance exercise.<span class="oui-sup">†︎</span>',
    },
    {
        image: '2024-STS-Wpage-Ingredient-5.png',
        imageWidth: 560,
        imageHeight: 560,
        title: 'Vitamin B12 as Methylcobalamin',
        summary: 'The active form of Vitamin B-12.',
        detail: 'Helps the body produce red blood cells, support healthy nerve cells, and promote cellular energy for exercise.<span class="oui-sup">†︎</span>',
    },
];

export default ingredients;
