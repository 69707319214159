import React, { ReactNode } from "react";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import Box from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import {DashLogo} from "@onnit-js/ui/components/logo/OnnitLogo";

interface Props {
    bscg?: boolean;
    nsf?: boolean;
}

const Certification = ({
    image,
    title,
    description
}: {
    image: ReactNode;
    title: string;
    description: string;
}) => {
    return (
        <Box
            flex="1 1 auto"
            width={1}
            maxWidth={["100%", "100%", 400]}
            mx={[0, 0, 5]}
            my={7}
            display={["block", "flex"]}>
            <Box
                mb={3}
                mr={[0, 5]}
                display={["flex", "block"]}
                justifyContent={["center", "flex-start"]}
                alignItems={["center", "flex-start"]}>
                {image}
            </Box>
            <Box flex="1">
                <Text
                    color="white"
                    fontSize={4}
                    fontWeight="bold"
                    mb={3}
                    textAlign={["center", "left"]}>
                    {title}
                </Text>
                <Text color="white" typeStyle="default" textAlign={["center", "left"]}>
                    {description}
                </Text>
            </Box>
        </Box>
    );
};

function QualityPromise({ bscg = false, nsf = false}: Props) {
    const onnitContext = useOnnitContext();
    if (!onnitContext) return null;
    const IMG_URL = onnitContext.url.cdn.static_images;

    return (
        <Box bg="grays.8" px={5} py={[8, 8, 10]}>
            <Box maxWidth="1920px" mx="auto">
                <Box display="flex" alignItems="center" flexDirection="column" mb={7}>
                    <DashLogo bg="white" color="grays.8" mb={5} />
                    <Text
                        textAlign="center"
                        color="white"
                        fontSize={[6, 8, 10, 12]}
                        lineHeight={5}
                        fontWeight="bold"
                        fontStyle="italic"
                        mb={2}>
                        Onnit Quality Promise
                    </Text>
                    <Text color="white" maxWidth="664px" typeStyle="copy02" textAlign="center">
                        We are our own customer. All Onnit products are carefully sourced with the
                        finest ingredients and rigorously tested for efficacy, purity, and safety.
                    </Text>
                </Box>
                <Box
                    display={["block", "flex"]}
                    flexDirection={["column", "column", "row"]}
                    justifyContent="center">
                    {bscg && (
                        <Certification
                            image={
                                <img
                                    src={`${IMG_URL}/product-page/bscg-drug-free.png`}
                                    alt="BSCG"
                                    style={{ width: "100%", display: "block", maxWidth: 100 }}
                                />
                            }
                            title="Certified Drug Free"
                            description="BSCG's rigorous science-first program provides assurance that products and ingredients are
                            free of drugs and other harmful agents that can lead to health concerns or positive drug
                            tests."
                        />
                    )}
                    {nsf && (
                        <Certification
                            image={
                                <img
                                    src={`${IMG_URL}/product-page/oui-new-mood/nsf-logo-ko.svg`}
                                    alt="NSF"
                                    style={{ width: "100%", display: "block", maxWidth: 90 }}
                                />
                            }
                            title="NSF Certified for Sport"
                            description="Widely recognized as the “gold standard” for quality in such areas as water systems, food safety, and health sciences including dietary supplements."
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default QualityPromise;
