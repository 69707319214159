import React, { useState } from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Box from "@onnit-js/ui/components/box/Box";
import Text, { List, ListItem, Sup } from "@onnit-js/ui/components/text";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import usePageData from "../../hooks/usePageData";
import { Img, Picture } from "@onnit-js/ui/components/image";
import QualityPromise from "../../components/common/QualityPromise";
import SupplementFacts from "../../components/common/SupplementFacts";
import ProductImageGrid from "@onnit-js/ui/components/module/ProductImageGrid";
import Grid from "@onnit-js/ui/components/module/grid/12/Grid";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import MediaContentShowcase from "@onnit-js/ui/components/module/MediaContentShowcase";
import Embed from "@onnit-js/ui/components/video/Embed";
import ingredients from "./ingredients";
import DefaultProductPageTop from "../../components/common/layouts/DefaultProductPageTop";
import UsageAndDisclaimers from "../../components/common/UsageAndDisclaimers";
import config from "../../config/config";
import { Testimonial } from "@onnit-js/ui/components/module/testimonials";

function Page() {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    const [currentPID, setCurrentPID] = useState<number | undefined>();

    if (!pageData || !onnitContext) return null;

    const IMG_URL = onnitContext.url.cdn.static_images + "/product-page/shroomtech-sport";
    const IMG_PARAMS = config.IMG_PARAMS;

    const colors = {
        lightBlue: "#068BC6",
        blackish: "#161616",
    };

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />

                <DefaultProductPageTop
                    onProductChange={(product) => {
                        setCurrentPID(product.id);
                    }}
                >
                    <ProductImageGrid>
                        <Picture
                            lazyLoad={false}
                            width={944}
                            height={424}
                            src={[
                                `${IMG_URL}/2024-STS-Wpage-Gallery-1-920x920.jpg${IMG_PARAMS}`,
                                `${IMG_URL}/2024-STS-Wpage-Gallery-1-1940x873.jpg${IMG_PARAMS}`,
                            ]}
                            alt="Athlete with Onnit Whey Protein"
                        />
                        <Img
                            alt="Shroom Tech® SPORT ingredient"
                            src={`${IMG_URL}/2024-STS-Wpage-Gallery-2-920x920.jpg${IMG_PARAMS}`}
                        />
                        <Img
                            alt="Shroom Tech® SPORT ingredient"
                            src={`${IMG_URL}/2024-STS-Wpage-Gallery-3-920x920.jpg${IMG_PARAMS}`}
                        />
                        <Picture
                            lazyLoad={false}
                            width={944}
                            height={424}
                            src={[
                                `${IMG_URL}/2024-STS-Wpage-Gallery-4-920x920.jpg${IMG_PARAMS}`,
                                `${IMG_URL}/2024-STS-Wpage-Gallery-4-1940x873.jpg${IMG_PARAMS}`,
                            ]}
                            alt="Athlete with Onnit Whey Protein"
                        />
                    </ProductImageGrid>
                </DefaultProductPageTop>

                <div className="hide-ie">
                    <Box bg={colors.blackish} py={[6, 6, 6, 8]}>
                        <TwelveColumn display={["none", "none", "grid"]} mb={4}>
                            <Box gridColumn="2 / span 7" px={4}>
                                <Text as="h2" color="grays.3" typeStyle="text02" textTransform="uppercase">
                                    Shroom Tech<Sup variant="reg" aria-hidden="true" />{" "}SPORT
                                </Text>
                            </Box>
                        </TwelveColumn>
                        <TwelveColumn display={["block", "block", "grid"]} mb={[0, 0, 6]}>
                            <Box gridColumn="7 / span 5" px={4}>
                                <Embed
                                    title="Shroom Tech® SPORT Product Video"
                                    src="https://player.vimeo.com/video/361907625"
                                    width="640"
                                    height="640"
                                />
                            </Box>
                            <Box
                                gridColumn="2 / span 5"
                                display={["block", "block", "flex"]}
                                alignItems="center"
                                px={4}
                            >
                                <Text
                                    display={["block", "block", "none"]}
                                    as="h2"
                                    color="grays.3"
                                    typeStyle="text02"
                                    textTransform="uppercase"
                                    my={3}
                                >
                                    Shroom Tech<Sup variant="reg" aria-hidden="true" />{" "}SPORT
                                </Text>

                                <Text as="p" typeStyle="title01" color={colors.lightBlue} textTransform="uppercase" mb={2}>
                                    Go Faster<br />
                                    Go Longer<br />
                                    More Reps<Sup variant="dagger" />
                                </Text>
                            </Box>
                        </TwelveColumn>

                        <TwelveColumn display={["block", "block", "grid"]}>
                            <Box gridColumn="2 / span 7" px={4} mb={[6, 6, 6, 8]}>
                                <Text as="p" color="grays.2" typeStyle="text02" mb={4}>
                                    Whether you're a recreational exerciser or a dedicated athlete, Shroom Tech<Sup variant="reg" aria-hidden="true" />{" "}SPORT means MORE: more standing on the
                                    pedals for the mom who does spin class; more
                                    reps for the gym rat on his favorite lift; more shifts for the hockey player who used to get winded on the ice, and so on.<Sup variant="dagger" />{" "}Designed to
                                    be used BEFORE EXERCISE —
                                    or — FOR DAILY ENERGY SUPPORT<Sup variant="dagger" />
                                </Text>
                            </Box>
                            <Box gridColumn="3 / span 7" px={4}>
                                <Text as="h2" typeStyle="title01" mb={4} textTransform="uppercase" color={colors.lightBlue}>
                                    Shroom Tech<Sup variant="reg" aria-hidden="true" />{" "}SPORT is for:
                                </Text>
                                <List>
                                    <ListItem typeStyle="text02" color="grays.2">
                                        Athletes
                                    </ListItem>
                                    <ListItem typeStyle="text02" color="grays.2">
                                        Recreational gym goers
                                    </ListItem>
                                    <ListItem typeStyle="text02" color="grays.2">
                                        People who want to be called a "cardio machine!"
                                    </ListItem>
                                    <ListItem typeStyle="text02" color="grays.2">
                                        People who want energy for exercise or active lifestyle performance
                                    </ListItem>
                                </List>
                            </Box>
                        </TwelveColumn>
                    </Box>

                    <Box py={[6, 6, 8]}>
                        <Testimonial
                            quoteColor="grays.6"
                            imgSrc={`${IMG_URL}/2023-Wpage-Testimonial-Joe-224x135.png${IMG_PARAMS}`}
                            quote={`Joe Rogan has famously stated that Shroom Tech<span class="oui-sup" aria-hidden="true">®</span> SPORT helps him squeeze in an extra roll at the end of jiu-jitsu class.`}
                        />
                    </Box>

                    <Box bg="black" py={[6, 6, 8]}>
                        <Grid display={["block", "block", "block", "grid"]}>
                            <Box gridColumn={["1 / -1", "1/-1", "1 / -1", "3 /span 10"]} gridRow="1" px={[4, 4, 4, 0]}>
                                <Text typeStyle="copyDisplay02" color="white" display="block">
                                    Key Ingredients
                                </Text>
                            </Box>
                        </Grid>

                        <MediaContentShowcase
                            items={ingredients.map((x) => {
                                let copy = { ...x };
                                copy.image = `${IMG_URL}/${copy.image}${IMG_PARAMS}`;
                                return copy;
                            })}
                            textColor="white"
                            activeColor={colors.lightBlue}
                            bgMedia={
                                <Img
                                    aria-hidden="true"
                                    placeholder={[754, 208]}
                                    src={`${IMG_URL}/2024-STS-Wpage-Ingredient-Background.jpg${IMG_PARAMS}`}
                                    alt="Mountain scenery."
                                />
                            }
                        />
                    </Box>

                    <ImageContentPortrait
                        reverse={true}
                        image={
                            <Img
                                alt="Neen Williams bicep curling."
                                placeholder={[464, 600]}
                                src={`${IMG_URL}/2024-STS-Wpage-How.jpg${IMG_PARAMS}`}
                                width={926}
                                height={1200}
                            />
                        }
                        content={
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                <Text as="h2" typeStyle="copyDisplay02" mb={[4, 4, 6]}>
                                    How Shroom Tech<Sup variant="reg" />{" "}SPORT Works
                                </Text>
                                <Text as="p" fontWeight="regular" color="grays.6" typeStyle="text02" mb={[4, 4, 5]}>
                                    Just as many vehicles have governors on their engines to limit the speed they can go, your body is limited by its own ability to produce cellular energy. Shroom
                                    Tech<Sup variant="reg" />{" "}SPORT takes the governor off. By promoting your capacity to produce energy, you can push your body for longer periods.<Sup
                                    variant="dagger" />
                                </Text>
                            </Box>
                        }
                    />

                    <ImageContentPortrait
                        mt={[6, 6, 8]}
                        reverse={true}
                        image={
                            <Img
                                alt="Hand holding bottle of Shroom Tech SPORT."
                                placeholder={[464, 600]}
                                src={`${IMG_URL}/2023-STS-Wpage-ProductTesting.jpg${IMG_PARAMS}`}
                                width={926}
                                height={1200}
                            />
                        }
                        content={
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                <Text as="h2" typeStyle="copyDisplay02" mb={[4, 4, 6]}>
                                    Product Testing
                                </Text>
                                <Text as="p" fontWeight="regular" color="grays.6" typeStyle="text02" mb={[4, 4, 5]}>
                                    The Shroom Tech<Sup variant="reg" />{" "}SPORT formula was tested in a 12-week double-blind, placebo-controlled, clinical trial at Florida State University. The
                                    results showed statistically significant increases in performance.<Sup variant="dagger" />
                                </Text>
                                <Text as="p" fontWeight="regular" color="grays.6" typeStyle="text02" mb={[4, 4, 5]}>
                                    Shroom Tech<Sup variant="reg" />{" "} SPORT was shown to promote gains in training volume during high-intensity cardio and strength training.<Sup
                                    variant="dagger" />
                                </Text>
                            </Box>
                        }
                    />
                </div>

                <UsageAndDisclaimers supplementFacts={<SupplementFacts currentPID={currentPID} />}>
                    <Text typeStyle="caption02" color="grays.5" mb={4}>
                        <Text as="span" fontWeight="bold" display="inline">WARNING:</Text> Consult a medical doctor before taking this or any other nutritional supplement if you are pregnant,
                        nursing, have or
                        suspect a medical condition, or are
                        taking any medications. <Text as="span" fontWeight="bold" display="inline">Store in a cool, dry place.</Text>{" "}
                        <Text as="span" fontWeight="bold" mt={5}>
                            KEEP OUT OF REACH OF CHILDREN.
                        </Text>
                    </Text>
                </UsageAndDisclaimers>

                {/* Per Lindsey, the only product that is bscg certified is STS*/}
                <QualityPromise bscg={true} nsf={false} />
            </>
        </ThemeProvider>
    );
}

export default Page;
